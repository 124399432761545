@import url(https://fonts.googleapis.com/css?family=DM+Serif+Display&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  box-sizing: inherit;
  vertical-align: baseline;
}
a {
  color: inherit;
  text-decoration: none;
  box-shadow: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: "DM Serif Display", serif;
  font-weight: 300;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.About {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 70px;
  overflow: hidden; }
  .About__Content {
    max-width: 690px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .About__Content:lang(ru) {
      max-width: 800px; }
    .About__Content__Title {
      font-size: 4.4rem;
      font-family: Quarto A, Quarto B, Baskerville, Baskerville Old Face, Hoefler Text, Garamond, Times New Roman, serif;
      letter-spacing: 0.5px;
      line-height: 1;
      font-weight: 800;
      color: #222222;
      text-align: center; }
      .About__Content__Title:lang(ru) {
        font-size: 4.2rem; }
    .About__Content__Text {
      margin-top: 35px;
      text-align: center;
      font-size: 1.8rem;
      font-family: Helvetica Neue, helvetica, arial, sans-serif;
      line-height: 1.72;
      font-weight: 300;
      color: #222222; }
      .About__Content__Text:lang(ru) {
        max-width: 700px; }
  .About__Parallax {
    position: relative;
    pointer-events: none;
    z-index: -100; }
    .About__Parallax__Image {
      height: 400px; }
  @media only screen and (max-width: 768px) {
    .About {
      padding-top: 50px; }
      .About__Content {
        margin: 0px 20px; }
        .About__Content__Title {
          font-size: 3rem !important; }
          .About__Content__Title:lang(ru) {
            font-size: 2.7rem !important; }
        .About__Content__Text {
          font-size: 1.5rem !important;
          text-align: justify; }
          .About__Content__Text:lang(ru) {
            font-size: 1.4rem !important;
            line-height: 1.68; }
      .About__Parallax__Image {
        height: 360px; } }

.ItemStack {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ItemStack__Card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px !important;
    height: 214px;
    box-shadow: 2px 2px 1px -2px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.06), 2px 1px 3px 0px rgba(0, 0, 0, 0.08) !important; }
    .ItemStack__Card__Background {
      width: 320px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      display: flex;
      justify-content: center;
      align-items: center; }
      .ItemStack__Card__Background__Logo {
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
  .ItemStack__Title {
    font-size: 2.3rem !important;
    font-weight: 500 !important;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    letter-spacing: 1px !important;
    margin-top: 16px !important; }
  .ItemStack__Subtitle {
    font-size: 1.8rem !important;
    margin-top: 4px !important; }

.Header {
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8; }
  .Header__Title {
    font-size: 4.8rem;
    letter-spacing: 0.2em;
    font-weight: 400; }
  @media only screen and (max-width: 900px) {
    .Header__Title {
      font-size: 4rem !important; } }

.ServiceGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 28px;
  row-gap: 28px;
  grid-column-gap: 24px;
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
  margin: 60px 0 20px 0; }
  @media only screen and (max-width: 1150px) {
    .ServiceGrid {
      grid-template-columns: 1fr 1fr; } }
  @media only screen and (max-width: 900px) {
    .ServiceGrid {
      grid-template-columns: 1fr; } }

.Mobile {
  display: flex;
  flex-direction: column; }
  .Mobile__Cover {
    height: 40vh;
    width: 100%;
    background-image: url(/static/media/mobile.93e0b2ef.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Mobile__Cover__Title {
      font-size: 7rem;
      font-weight: bold; }
  .Mobile__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px; }
    .Mobile__Content__Wrapper {
      max-width: 50vw; }
      @media only screen and (max-width: 1300px) {
        .Mobile__Content__Wrapper {
          max-width: 70vw; } }
      .Mobile__Content__Wrapper__Title {
        font-size: 3.2rem;
        letter-spacing: 0.5px;
        padding-bottom: 24px; }
      .Mobile__Content__Wrapper__Text {
        font-size: 1.7rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.6;
        font-weight: 300;
        color: #222222; }
  @media only screen and (max-width: 900px) {
    .Mobile {
      height: unset; }
      .Mobile__Cover {
        height: 40vh; }
        .Mobile__Cover__Title {
          font-size: 6rem; }
          .Mobile__Cover__Title:lang(ru) {
            font-size: 5rem; }
      .Mobile__Content {
        height: unset;
        padding: 36px 32px 56px 32px; }
        .Mobile__Content__Wrapper__Title {
          font-size: 2.8rem !important; }
          .Mobile__Content__Wrapper__Title:lang(ru) {
            font-size: 2.6rem !important;
            font-weight: 600; }
        .Mobile__Content__Wrapper__Text {
          max-width: 70vw;
          font-size: 1.6rem !important;
          text-align: justify; }
          .Mobile__Content__Wrapper__Text:lang(ru) {
            font-size: 1.5rem !important; } }

.Backend {
  display: flex;
  flex-direction: column; }
  .Backend__Cover {
    height: 40vh;
    width: 100%;
    background-image: url(/static/media/backend.b04881b5.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Backend__Cover__Title {
      font-size: 7rem;
      color: white;
      font-weight: bold; }
  .Backend__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px; }
    .Backend__Content__Wrapper {
      max-width: 50vw; }
      @media only screen and (max-width: 1300px) {
        .Backend__Content__Wrapper {
          max-width: 70vw; } }
      .Backend__Content__Wrapper__Title {
        font-size: 3.2rem;
        letter-spacing: 0.5px;
        padding-bottom: 24px; }
      .Backend__Content__Wrapper__Text {
        font-size: 1.7rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.6;
        font-weight: 300;
        color: #222222; }
  @media only screen and (max-width: 900px) {
    .Backend {
      height: unset; }
      .Backend__Cover {
        height: 40vh; }
        .Backend__Cover__Title {
          font-size: 6rem; }
          .Backend__Cover__Title:lang(ru) {
            font-size: 5.6rem; }
      .Backend__Content {
        height: unset;
        padding: 36px 32px 56px 32px; }
        .Backend__Content__Wrapper__Title {
          font-size: 2.8rem !important; }
          .Backend__Content__Wrapper__Title:lang(ru) {
            font-size: 2.6rem !important;
            font-weight: 600; }
        .Backend__Content__Wrapper__Text {
          max-width: 70vw;
          font-size: 1.6rem !important;
          text-align: justify; }
          .Backend__Content__Wrapper__Text:lang(ru) {
            font-size: 1.5rem !important; } }

.Web {
  display: flex;
  flex-direction: column; }
  .Web__Cover {
    height: 40vh;
    width: 100%;
    background-image: url(/static/media/web.2b525d8f.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Web__Cover__Title {
      font-size: 7rem;
      font-weight: bold; }
  .Web__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px; }
    .Web__Content__Wrapper {
      max-width: 50vw; }
      @media only screen and (max-width: 1300px) {
        .Web__Content__Wrapper {
          max-width: 70vw; } }
      .Web__Content__Wrapper__Title {
        font-size: 3.2rem;
        letter-spacing: 0.5px;
        padding-bottom: 24px; }
      .Web__Content__Wrapper__Text {
        font-size: 1.7rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.6;
        font-weight: 300;
        color: #222222; }
  @media only screen and (max-width: 900px) {
    .Web {
      height: unset; }
      .Web__Cover {
        height: 40vh; }
        .Web__Cover__Title {
          font-size: 6rem; }
          .Web__Cover__Title:lang(ru) {
            font-size: 5.6rem; }
      .Web__Content {
        height: unset;
        padding: 36px 32px 56px 32px; }
        .Web__Content__Wrapper__Title {
          font-size: 2.8rem !important; }
          .Web__Content__Wrapper__Title:lang(ru) {
            font-size: 2.6rem !important;
            font-weight: 600; }
        .Web__Content__Wrapper__Text {
          max-width: 70vw;
          font-size: 1.6rem !important;
          text-align: justify; }
          .Web__Content__Wrapper__Text:lang(ru) {
            font-size: 1.5rem !important; } }

.Arrow {
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  margin-top: 10px;
  border-style: solid;
  border-color: white;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: border-width 150ms ease-in-out;
  transition: border-width 150ms ease-in-out;
  -webkit-animation: arrow-appear 2s forwards 2.6s;
          animation: arrow-appear 2s forwards 2.6s;
  visibility: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; }

@-webkit-keyframes arrow-appear {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes arrow-appear {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

.CustomOutlineButton {
  border-radius: 0 !important;
  letter-spacing: 3px !important;
  color: white !important;
  border-color: white !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important; }
  .CustomOutlineButton:hover {
    background-color: white !important;
    color: black !important; }
  @media only screen and (max-width: 768px) {
    .CustomOutlineButton {
      font-size: 1.5rem !important; } }

.CustomOutlineButton--darkfilled {
  border-radius: 0 !important;
  letter-spacing: 3px !important;
  background-color: black !important;
  color: white !important;
  -webkit-transition: background-color 0.4s, color 0.4s, font-size 0.4s !important;
  transition: background-color 0.4s, color 0.4s, font-size 0.4s !important; }
  .CustomOutlineButton--darkfilled:hover {
    color: black !important;
    border: 2px solid black !important;
    background-color: transparent !important; }
  .CustomOutlineButton--darkfilled:disabled {
    background-color: #0000001f !important;
    border: none !important; }
  .CustomOutlineButton--darkfilled__Progressbar {
    color: white !important;
    padding: 3px; }
  @media only screen and (max-width: 768px) {
    .CustomOutlineButton--darkfilled {
      font-size: 1.5rem !important; } }

.Portfolio {
  display: flex; }
  .Portfolio__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 80px 20px 60px 20px; }
    .Portfolio__Wrapper__Title {
      font-size: 5.4rem;
      font-family: Quarto A, Quarto B, Baskerville, Baskerville Old Face, Hoefler Text, Garamond, Times New Roman, serif;
      letter-spacing: -0.012em;
      line-height: 1;
      font-weight: 800;
      color: #222222;
      margin-bottom: 40px;
      text-align: center; }
    .Portfolio__Wrapper__Carousel {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .Portfolio__Wrapper__Carousel__Parent {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 900px;
        width: 100%; }
        .Portfolio__Wrapper__Carousel__Parent__PortfolioItem {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Title {
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 12px;
            color: #222222;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            font-family: Helvetica Neue, helvetica, arial, sans-serif;
            cursor: pointer;
            -webkit-transition: 0.3s;
            transition: 0.3s; }
            .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Title:hover {
              color: #d50000; }
          .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Subtitle {
            font-size: 2.1rem;
            color: #222222;
            font-family: Helvetica Neue, helvetica, arial, sans-serif;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            cursor: pointer;
            -webkit-transition: 0.3s;
            transition: 0.3s; }
            .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Subtitle:hover {
              color: #d50000; }
          .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Image {
            object-fit: cover;
            object-position: center center;
            pointer-events: none;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            max-width: 840px; }
    .Portfolio__Wrapper__ImageLocationShower {
      background-color: white !important;
      justify-content: center; }
  @media only screen and (max-width: 900px) {
    .Portfolio__Wrapper {
      margin: 20px 0 30px 0; }
      .Portfolio__Wrapper__Title {
        font-size: 4rem;
        margin-bottom: 60px; }
      .Portfolio__Wrapper__Carousel__Parent__PortfolioItem {
        overflow: hidden; }
        .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Title {
          font-size: 2.5rem;
          margin-bottom: 12px; }
        .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Subtitle {
          font-size: 2rem; }
        .Portfolio__Wrapper__Carousel__Parent__PortfolioItem__Image {
          max-width: 105vw; } }

.Contacts {
  background-color: white;
  padding: 60px 0 80px 0; }
  .Contacts__Content {
    padding: 0 22px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto; }
    .Contacts__Content__Titles {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      .Contacts__Content__Titles__Title {
        font-size: 6rem;
        font-family: Quarto A, Quarto B, Baskerville, Baskerville Old Face, Hoefler Text, Garamond, Times New Roman, serif;
        letter-spacing: -0.012em;
        line-height: 1;
        font-weight: 800;
        color: #222222;
        margin-bottom: 30px; }
        .Contacts__Content__Titles__Title:lang(ru) {
          font-size: 5.6rem; }
      .Contacts__Content__Titles__Subtitle {
        font-size: 1.8rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.72;
        font-weight: 300;
        color: #222222;
        margin-bottom: 40px; }
    .Contacts__Content__Inputs {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .Contacts__Content__Inputs__InputField {
        width: 100%; }
        .Contacts__Content__Inputs__InputField:hover .Contacts__Content__Inputs__InputField__InputText {
          padding-left: 8px !important; }
        .Contacts__Content__Inputs__InputField:hover .Contacts__Content__Inputs__InputField__InputText--multi {
          padding-left: 8px !important; }
        .Contacts__Content__Inputs__InputField__InputText {
          color: #000000 !important;
          font-size: 1.7rem !important;
          padding-bottom: 4px !important;
          -webkit-transition: 0.3s !important;
          transition: 0.3s !important; }
        .Contacts__Content__Inputs__InputField__InputText--multi {
          color: #000000 !important;
          font-size: 1.7rem !important;
          padding-bottom: 12px !important;
          -webkit-transition: 0.3s !important;
          transition: 0.3s !important; }
        .Contacts__Content__Inputs__InputField__HelperText {
          font-size: 1.3rem !important; }
      .Contacts__Content__Inputs__Group {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        .Contacts__Content__Inputs__Group__InputField {
          width: 48%; }
          .Contacts__Content__Inputs__Group__InputField:hover .Contacts__Content__Inputs__InputField__InputText {
            padding-left: 8px !important; }
      .Contacts__Content__Inputs__Submit {
        margin-top: 40px !important;
        height: 54px;
        width: 260px; }
        .Contacts__Content__Inputs__Submit__Text {
          color: white !important;
          font-family: Roboto, Helvetica, Arial, sans-serif !important;
          font-size: 1.7rem !important;
          font-weight: 600 !important;
          letter-spacing: 1px !important; }
        .Contacts__Content__Inputs__Submit__ProgressBar {
          position: absolute !important;
          color: #222222 !important; }
    .Contacts__Content__Dialog__Title {
      font-size: 2rem !important; }
    .Contacts__Content__Dialog__Content {
      max-width: 500px; }
    .Contacts__Content__Dialog__CloseButton {
      color: #222222 !important;
      margin-top: -20px !important; }
      .Contacts__Content__Dialog__CloseButton__Text {
        font-size: 1.4rem !important;
        color: #222222 !important;
        font-weight: 500 !important;
        text-transform: none !important; }
  @media screen and (max-height: 700px) {
    .Contacts {
      padding: 40px 0 60px 0; }
      .Contacts__Content__Titles {
        max-width: 500px; }
        .Contacts__Content__Titles__Title {
          font-size: 5.5rem; }
        .Contacts__Content__Titles__Subtitle {
          font-size: 1.7rem;
          margin-bottom: 15px; }
      .Contacts__Content__Inputs__InputField__InputText {
        font-size: 1.6rem !important; }
      .Contacts__Content__Inputs__InputField__HelperText {
        font-size: 1.2rem !important; }
      .Contacts__Content__Inputs__Submit {
        margin-top: 35px !important;
        width: 240px;
        height: 48px; }
        .Contacts__Content__Inputs__Submit__Text {
          font-size: 1.5rem !important; } }
  @media screen and (max-width: 768px) {
    .Contacts {
      padding: 90px 0 60px 0; }
      .Contacts__Content {
        padding: 0 32px; }
        .Contacts__Content__Titles__Title {
          font-size: 3.5rem; }
          .Contacts__Content__Titles__Title:lang(ru) {
            font-size: 3.4rem; }
        .Contacts__Content__Titles__Subtitle {
          font-size: 1.7rem;
          margin-bottom: 50px; }
        .Contacts__Content__Inputs__Submit {
          margin-top: 50px !important;
          width: 220px; }
          .Contacts__Content__Inputs__Submit__Text {
            font-size: 1.6rem !important; } }

.Footer {
  padding: 60px 24px 40px 24px;
  background-color: #222222; }
  .Footer__Container {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    justify-content: space-between; }
    .Footer__Container__Contacts {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .Footer__Container__Contacts__Title {
        color: white;
        text-transform: uppercase;
        font-family: Avenir Next W01 Light, Helvetica Neue, helvetica, arial, sans-serif;
        font-weight: 600;
        font-size: 1.5rem;
        letter-spacing: 1px;
        padding-bottom: 16px; }
      .Footer__Container__Contacts__Text {
        color: #acacac;
        font-family: Avenir Next W01 Light, Helvetica Neue, helvetica, arial, sans-serif;
        font-size: 1.4rem;
        letter-spacing: 1px;
        padding-top: 8px; }
      .Footer__Container__Contacts__Icons {
        padding-top: 14px;
        margin-left: -12px; }
        .Footer__Container__Contacts__Icons__IconButton__Icon {
          color: white;
          font-size: 26px !important; }
      .Footer__Container__Contacts__Copyright {
        color: #acacac;
        font-family: Avenir Next W01 Light, Helvetica Neue, helvetica, arial, sans-serif;
        font-size: 1.4rem;
        letter-spacing: 1px;
        margin-top: 14px; }
    .Footer__Container__CopyrightWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end; }
      .Footer__Container__CopyrightWrapper__IconButton {
        margin: -12px -12px 0 0 !important; }
        .Footer__Container__CopyrightWrapper__IconButton__Icon {
          color: white;
          border: 3px solid white;
          border-radius: 100%;
          font-size: 22px !important;
          padding: 6px; }
      .Footer__Container__CopyrightWrapper__Copyright {
        color: #acacac;
        font-family: Avenir Next W01 Light, Helvetica Neue, helvetica, arial, sans-serif;
        text-align: end;
        font-size: 1.4rem;
        letter-spacing: 1px;
        margin-bottom: 12px; }

.Loading {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center; }

.HomePage {
  overflow: hidden; }

.PortfolioPageItem {
  width: 100%;
  height: 36vw;
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer; }
  .PortfolioPageItem:hover .PortfolioPageItem__Image {
    -webkit-clip-path: inset(24px 24px 0 24px);
            clip-path: inset(24px 24px 0 24px); }
  .PortfolioPageItem:hover .PortfolioPageItem__Wrapper__OpenIcon {
    padding: 3px;
    margin-right: -6px; }
  .PortfolioPageItem__Image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0);
    -webkit-transition: 0.2s;
    transition: 0.2s; }
  .PortfolioPageItem__Wrapper {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px; }
    .PortfolioPageItem__Wrapper__Name {
      font-size: 2rem;
      letter-spacing: 0.5px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 500; }
    .PortfolioPageItem__Wrapper__OpenIcon {
      color: black;
      border: 2px solid black;
      border-radius: 100%;
      font-size: 18px !important;
      padding: 2px;
      -webkit-transition: 0.3s !important;
      transition: 0.3s !important; }
  @media only screen and (max-width: 1120px) {
    .PortfolioPageItem {
      height: 55vw; } }
  @media only screen and (max-width: 720px) {
    .PortfolioPageItem {
      height: 105vw; } }

.PortfolioPage__Header {
  height: 30vh;
  background-size: cover;
  background-position: center 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .PortfolioPage__Header__Menu {
    padding: 35px 40px 12px 40px;
    width: 100%; }
    .PortfolioPage__Header__Menu__Logo {
      width: 40px;
      -webkit-filter: brightness(0) invert(0);
              filter: brightness(0) invert(0);
      height: auto;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent; }
  .PortfolioPage__Header__Title {
    font-size: 7.5rem;
    color: black;
    margin-top: -40px;
    font-weight: bold; }

.PortfolioPage__Projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 8px 4px; }

@media only screen and (max-width: 1120px) {
  .PortfolioPage__Header__Title {
    font-size: 7rem; } }
  @media only screen and (max-width: 1120px) and (max-width: 900px) {
    .PortfolioPage__Header__Title {
      font-size: 6rem;
      margin-top: -25px; } }

@media only screen and (max-width: 1120px) {
  .PortfolioPage__Projects {
    grid-template-columns: 1fr 1fr; } }

@media only screen and (max-width: 720px) {
  .PortfolioPage__Header {
    height: 210px; }
    .PortfolioPage__Header__Title {
      font-size: 5.5rem;
      margin-top: -15px; }
      .PortfolioPage__Header__Title:lang(ru) {
        font-size: 5.1rem;
        margin-top: -10px; } }
    @media only screen and (max-width: 720px) and (max-width: 400px) {
      .PortfolioPage__Header__Title {
        font-size: 5rem;
        margin-top: -6px; } }

@media only screen and (max-width: 720px) {
  .PortfolioPage__Projects {
    grid-template-columns: 1fr; } }

