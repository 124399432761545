$black-color: #222222;

.Contacts {
  background-color: white;
  padding: 60px 0 80px 0;

  &__Content {
    padding: 0 22px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;

    &__Titles {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      &__Title {
        font-size: 6rem;
        font-family: Quarto A, Quarto B, Baskerville, Baskerville Old Face,
          Hoefler Text, Garamond, Times New Roman, serif;
        letter-spacing: -0.012em;
        line-height: 1;
        font-weight: 800;
        color: $black-color;
        margin-bottom: 30px;

        &:lang(ru) {
          font-size: 5.6rem;
        }
      }

      &__Subtitle {
        font-size: 1.8rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.72;
        font-weight: 300;
        color: $black-color;
        margin-bottom: 40px;
      }
    }

    &__Inputs {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__InputField {
        width: 100%;

        &:hover {
          .Contacts__Content__Inputs__InputField__InputText {
            padding-left: 8px !important;
          }

          .Contacts__Content__Inputs__InputField__InputText--multi {
            padding-left: 8px !important;
          }
        }

        &__InputText {
          color: #000000 !important;
          font-size: 1.7rem !important;
          padding-bottom: 4px !important;
          transition: 0.3s !important;
        }

        &__InputText--multi {
          color: #000000 !important;
          font-size: 1.7rem !important;
          padding-bottom: 12px !important;
          transition: 0.3s !important;
        }

        &__HelperText {
          font-size: 1.3rem !important;
        }
      }

      &__Group {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__InputField {
          width: 48%;

          &:hover {
            .Contacts__Content__Inputs__InputField__InputText {
              padding-left: 8px !important;
            }
          }
        }
      }

      &__Submit {
        margin-top: 40px !important;
        height: 54px;
        width: 260px;

        &__Text {
          color: white !important;
          font-family: Roboto, Helvetica, Arial, sans-serif !important;
          font-size: 1.7rem !important;
          font-weight: 600 !important;
          letter-spacing: 1px !important;
        }

        &__ProgressBar {
          position: absolute !important;
          color: $black-color !important;
        }
      }
    }

    &__Dialog {
      &__Title {
        font-size: 2rem !important;
      }

      &__Content {
        max-width: 500px;
      }

      &__CloseButton {
        color: $black-color !important;
        margin-top: -20px !important;

        &__Text {
          font-size: 1.4rem !important;
          color: $black-color !important;
          font-weight: 500 !important;
          text-transform: none !important;
        }
      }
    }
  }

  @media screen and (max-height: 700px) {
    padding: 40px 0 60px 0;

    &__Content {
      &__Titles {
        max-width: 500px;

        &__Title {
          font-size: 5.5rem;
        }
        &__Subtitle {
          font-size: 1.7rem;
          margin-bottom: 15px;
        }
      }

      &__Inputs {
        &__InputField {
          &__InputText {
            font-size: 1.6rem !important;
          }
          &__HelperText {
            font-size: 1.2rem !important;
          }
        }

        &__Submit {
          margin-top: 35px !important;
          width: 240px;
          height: 48px;

          &__Text {
            font-size: 1.5rem !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 90px 0 60px 0;

    &__Content {
      padding: 0 32px;

      &__Titles {
        &__Title {
          font-size: 3.5rem;

          &:lang(ru) {
            font-size: 3.4rem;
          }
        }
        &__Subtitle {
          font-size: 1.7rem;
          margin-bottom: 50px;
        }
      }

      &__Inputs {
        &__Submit {
          margin-top: 50px !important;
          width: 220px;

          &__Text {
            font-size: 1.6rem !important;
          }
        }
      }
    }
  }
}
