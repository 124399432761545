.PortfolioPageItem {
  width: 100%;
  height: 36vw;
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    .PortfolioPageItem__Image {
      clip-path: inset(24px 24px 0 24px);
    }

    .PortfolioPageItem__Wrapper__OpenIcon {
      padding: 3px;
      margin-right: -6px;
    }
  }

  &__Image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    clip-path: inset(0 0 0 0);
    transition: 0.2s;
  }

  &__Wrapper {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;

    &__Name {
      font-size: 2rem;
      letter-spacing: 0.5px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 500;
    }

    &__OpenIcon {
      color: black;
      border: 2px solid black;
      border-radius: 100%;
      font-size: 18px !important;
      padding: 2px;
      transition: 0.3s !important;
    }
  }

  @media only screen and (max-width: 1120px) {
    height: 55vw;
  }

  @media only screen and (max-width: 720px) {
    height: 105vw;
  }
}
