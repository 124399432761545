$black-card-color: rgba(
  $color: black,
  $alpha: 0.8,
);

$white-card-color: rgba(
  $color: white,
  $alpha: 0.9,
);

.Header {
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;

  &__Title {
    font-size: 4.8rem;
    letter-spacing: 0.2em;
    font-weight: 400;
  }

  @media only screen and (max-width: 900px) {
    &__Title {
      font-size: 4rem !important;
    }
  }
}

.ServiceGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 28px;
  column-gap: 24px;
  margin: 60px 0 20px 0;

  @media only screen and (max-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.Mobile {
  display: flex;
  flex-direction: column;

  &__Cover {
    height: 40vh;
    width: 100%;
    background-image: url("../../assets/images/services/mobile.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    &__Title {
      font-size: 7rem;
      font-weight: bold;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;

    &__Wrapper {
      max-width: 50vw;

      @media only screen and (max-width: 1300px) {
        max-width: 70vw;
      }

      &__Title {
        font-size: 3.2rem;
        letter-spacing: 0.5px;
        padding-bottom: 24px;
      }

      &__Text {
        font-size: 1.7rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.6;
        font-weight: 300;
        color: #222222;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    height: unset;

    &__Cover {
      height: 40vh;

      &__Title {
        font-size: 6rem;

        &:lang(ru) {
          font-size: 5rem;
        }
      }
    }

    &__Content {
      height: unset;
      padding: 36px 32px 56px 32px;

      &__Wrapper {
        &__Title {
          font-size: 2.8rem !important;

          &:lang(ru) {
            font-size: 2.6rem !important;
            font-weight: 600;
          }
        }

        &__Text {
          max-width: 70vw;
          font-size: 1.6rem !important;
          text-align: justify;

          &:lang(ru) {
            font-size: 1.5rem !important;
          }
        }
      }
    }
  }
}

.Backend {
  display: flex;
  flex-direction: column;

  &__Cover {
    height: 40vh;
    width: 100%;
    background-image: url("../../assets/images/services/backend.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    &__Title {
      font-size: 7rem;
      color: white;
      font-weight: bold;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;

    &__Wrapper {
      max-width: 50vw;

      @media only screen and (max-width: 1300px) {
        max-width: 70vw;
      }

      &__Title {
        font-size: 3.2rem;
        letter-spacing: 0.5px;
        padding-bottom: 24px;
      }

      &__Text {
        font-size: 1.7rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.6;
        font-weight: 300;
        color: #222222;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    height: unset;

    &__Cover {
      height: 40vh;

      &__Title {
        font-size: 6rem;

        &:lang(ru) {
          font-size: 5.6rem;
        }
      }
    }

    &__Content {
      height: unset;
      padding: 36px 32px 56px 32px;

      &__Wrapper {
        &__Title {
          font-size: 2.8rem !important;

          &:lang(ru) {
            font-size: 2.6rem !important;
            font-weight: 600;
          }
        }

        &__Text {
          max-width: 70vw;
          font-size: 1.6rem !important;
          text-align: justify;

          &:lang(ru) {
            font-size: 1.5rem !important;
          }
        }
      }
    }
  }
}

.Web {
  display: flex;
  flex-direction: column;

  &__Cover {
    height: 40vh;
    width: 100%;
    background-image: url("../../assets/images/services/web.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    &__Title {
      font-size: 7rem;
      font-weight: bold;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;

    &__Wrapper {
      max-width: 50vw;

      @media only screen and (max-width: 1300px) {
        max-width: 70vw;
      }

      &__Title {
        font-size: 3.2rem;
        letter-spacing: 0.5px;
        padding-bottom: 24px;
      }

      &__Text {
        font-size: 1.7rem;
        font-family: Helvetica Neue, helvetica, arial, sans-serif;
        line-height: 1.6;
        font-weight: 300;
        color: #222222;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    height: unset;

    &__Cover {
      height: 40vh;

      &__Title {
        font-size: 6rem;

        &:lang(ru) {
          font-size: 5.6rem;
        }
      }
    }

    &__Content {
      height: unset;
      padding: 36px 32px 56px 32px;

      &__Wrapper {
        &__Title {
          font-size: 2.8rem !important;

          &:lang(ru) {
            font-size: 2.6rem !important;
            font-weight: 600;
          }
        }

        &__Text {
          max-width: 70vw;
          font-size: 1.6rem !important;
          text-align: justify;

          &:lang(ru) {
            font-size: 1.5rem !important;
          }
        }
      }
    }
  }
}
